// process.env.NODE_ENV === 'development' ? '/api' : production2

import { api, randomString } from '@/utils/index'
import { Login } from '@/interface/index'
import { SET_SIGNATURE } from '@/utils/constant'
const sha1 = require('js-sha1')
const wx = require('weixin-js-sdk') 

const base = 'https://api.fanwoon.com'//=== 'development' ? '/api' : ''


let OMS = ''
let TOB = ''

if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
    TOB = '/api'
} else {
    OMS = base + '/oms'
    TOB = base + '/tob'
}

/**
 * 获取任务详情
 * @param data 
 */
export const taskDetail = (data: any) => {
    const path = TOB + '/task/detail'
    return api.post(path, data)
}

/**
 * 通过状态范围获取任务
 * @param data 
 */
export const listByRangeStatus = (data: any) => {
    const path = TOB + '/task/listByRangeStatus'
    return api.post(path, data)
}

/**
 * 已完成任务
 * @param data 
 */
export const finishList = (data: any) => {
    const path = TOB + '/task/finishList'
    return api.post(path, data)
}

/**
 * 通过状态获取任务
 * @param data 
 */
export const listByStatus = (data: any) => {
    const path = TOB + '/task/listByStatus'
    return api.post(path, data)
}

/**
 * 申请奖励结算延长
 * @param {*} data 
 */
export const delayReward = (data: any) => {
    const path = TOB + '/task/delay/apple/reward'
    return api.post(path, data)
}

export const findBySearch = (data: any) => {
    const path = TOB + '/account/oms/findBySearch'
    return api.post(path, data)
}

/**
 * 获取板块
 * @param {*} data 
 */
export const circlesParent = (data: any) => {
    const path = OMS + '/dict/circles/parent'
    return api.post(path, data)
}

/**
 * 撤销退款
 * @param data 
 */
export const refundCancel = (data: any) => {
    const path = TOB + '/transaction/refund/cancel'
    return api.post(path, data)
}

/**
 * 申请退款
 * @param data 
 */
export const refundCreate = (data: any) => {
    const path = TOB + '/transaction/refund/create'
    return api.post(path, data)
}

/**
 * 支付预览
 * @param data 
 */
export const taskTrowse = (data: any) => {
    const path = TOB + '/task/browse'
    return api.post(path, data)
}

/**
 * 交易 - 支付订单 支付订单(name=JSAPI微信中调起支付，NATIVE扫码支付)
 * @param {*} data 
 */
export const orderPay = (data: any) => {
    const path = TOB + '/transaction/order/pay'
    return api.post(path, data)
}

/**
 * 交易 - 创建订单
 * @param {*} data 
 */
export const orderCreate = (data: any) => {
    const path = TOB + '/transaction/order/create'
    return api.post(path, data)
}

/**
 * 退款明细
 * @param {*} data 
 */
export const refundList = (data: any) => {
    const path = TOB + '/transaction/refund/list'
    return api.post(path, data)
}

/**
 * 订单列表
 */
export const orderList = (data: any) => {

    const path = TOB + '/transaction/order/list'
    return api.post(path, data)
}

/**
 * 交易列表
 * @param {*} data 
 */
export const transactionList = (data: any) => {
    const path = TOB + '/transaction/list'
    return api.post(path, data)
}

/**
 * 余额查询
 */
export const remaining = () => {
    const path = TOB + '/transaction/remaining'
    return api.get(path, '')
}

/**
 * @description 退出
 * @param {*} data 表单数据
 */
export const accountLogout = () => {
    const path = TOB + '/account/logout'
    return api.post(path, '')

}

/**
 * @description 微信认证
 * @param {data} 
 */
export const wxOaTh= (data: any) => {
    const path = TOB + '/wx/oauth'
    return api.post(path, data)
}

/**
 * @description 修改或新增企业
 * @param {data} 
 */
export const companyEditeSave = (data: any) => {
    const path = TOB + '/company/save'
    return api.post(path, data)
}

/**
 * @description 公司福利
 */
export const corporateWelfare = () => {
    const path = OMS + '/dict/corporateWelfare/list'
    return api.post(path, '')
}

/**
 * @description 获取公司详情
 */
export const companyDetail = () => {
    const path = TOB + '/company/detail'
    return api.post(path, '')
}

/**
 * @description 变更用户信息
 * @param {data} 
 */
export const accountUpdate = (data: any) => {
    const path = TOB + '/account/update'
    return api.post(path, data)
}

/**
 * 上传图片
 * @param data 
 */
export const upload = (data: any, type = 0) => {
    const path = `${OMS}/upload/uploadImage`
    const config = {
        headers: {
            'path': type === 0 ? 'avatar' : 'companyLogo'
        }
    }
    return api.upload(path, data, config)
}

/**
 * @description 修改密码
 * @param {newPassword} 新密码 
 * @param {oldPassword} 旧密码
 */
export const setPassword = (data: any) => {
    const path = TOB + '/account/setPassword'
    return api.post(path, data)
}

/**
 * @description 完成入职
 * @param {*} data 
 */
export const taskFinish = (data: any) => {
    const path = TOB + '/task/finish'
    return api.post(path, data)
}

/**
 * 支付浏览并进入面试
 * @param {*} data 
 */
export const browseAndInterview = (data: any) => {
    const path = TOB + '/task/browseAndInterview'
    return api.post(path, data)
}

/**
 * @description 面试环节
 * @param {*} data 
 */
export const interview = (data: any) => {
    const path = TOB + '/task/interview'
    return api.post(path, data)
}

/**
 * @description 接受offer-进入入职
 * @param {*} data 
 */
export const taskEntry = (data: any) => {
    const path = TOB + '/task/entry'
    return api.post(path, data)
}

/**
 * @description 淘汰
 * @param {id} 任务编号
 * @param {name} 淘汰原因
 */
export const eliminate = (data: any) => {
    const path = TOB + '/task/eliminate'
    return api.post(path, data)
}

/**
 * @description 恢复
 * @param {id} 任务编号
 */
export const recover = (data: any) => {
    const path = TOB + '/task/recover'
    return api.post(path, data)
}

/**
 * @description 职位列表统计
 * @param {*} data 
 */
export const listStatisticsJob = (data: any) => {
    const path = TOB + '/job/listStatisticsJob'
    return api.post(path, data)
}

//delayReward

/**
 * @description 通过职位和状态和候选人名搜索任务
 * @param {*} data 
 */
export const listByJobAndStatusAndLikeName = (data: any) => {
    const path = TOB + '/task/listByJobAndStatusAndLikeName'
    return api.post(path, data)
}

/**
 * @description 跟进-简历列表
 * @param {data} 
 */
export const listJob = (data: any) => {
    const path = TOB + '/job/listJob'
    return api.post(path, data)
}


/**
 * @description 初始化签名
 * @param {data} 
 */
export const initJS = () => {
    const path = TOB + '/wx/initJS'
    return api.post(path, '')
}

/**
 * @description 查询职位信息
 * @param {data} 
 */
export const jobDetail = (data: any) => {
     const path = TOB + '/job/detail'
     return api.post(path, data)
 }

/**
 * @description 更新职位
 */
export const jobUpdate = (data: any) => {
     const path = TOB + '/job/update'
     return api.post(path, data)
 }
 
 /**
  * @description 创建职位
  */
 export const createJob = (data: any) => {
     const path = TOB + '/job/create'
     return api.post(path, data)
 }

/**
 * @description 职位亮点
 */
export const jobHighLights = () => {
     const path = OMS + '/dict/jobHighLights/list'
     return api.post(path, '')
 }

/**
 * @description 行业树
 */
export const industryList = () => {
     const path = OMS + '/dict/function/list'
     return api.post(path, '')
 }

/**
 * @description 通过名字查询职位 + 分页查询
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const listlikeName = (data: any) => {
     const path = TOB + '/job/listJobLikeName'
     return api.post(path, data)
 }

/**
 * @description 省市区
 */
export const province = () => {
     const path = OMS + '/dict/province/map'
     return api.post(path, '')
 }

/**
 * @description 工作经验
 * @param {*} data
 */
export const workExpList = () => {
     const path = OMS + '/dict/workExp/list'
     return api.post(path, '')
 }

/**
 * @description 学历
 * @param {*} data
 */
export const educationList = () => {
     const path = OMS + '/dict/education/list'
     return api.post(path, '')
 }

/**
 * @description 开启职位
 * @param {*} id 职位编号
 */
export const openJob = (data: any) => {
     const path = TOB + '/job/openJob'
     return api.post(path, data)
 }

/**
 * @description 关闭职位
 * @param {*} id 职位编号
 */
export const closeJob = (data: any) => {
     const path = TOB + '/job/closeJob'
     return api.post(path, data)
 }

/**
 * @description 通过名字查询公司
 * @param {*} data
 */
export const listCloseLikeName = (data: any) => {
     const path = TOB + '/job/listCloseLikeName'
     return api.post(path, data)
 }

/**
 * @description 通过名字 + 只查看自己的 - 招聘中
 * @param {*} data
 */
export const listOpenLikeName = (data: any) => {
     const path = TOB + '/job/listOpenLikeName'
     return api.post(path, data)
 }


/**
 * @description 注册账号
 * @param {*} data
 */
export const register = (data: any) => {
     const url = TOB + '/account/register'
     return api.post(url,data)
 }


/**
 * @description 查询企业信息 + 分页查询
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const listLikenameByCompany = (data: any) => {
     const url = OMS + '/company/listLikeName'
     return api.post(url,data)
 }


/**
 * @description 账号密码登录
 * @param {*} data
 */
export const accountLogin = (data: any) => {
     const url = TOB + '/account/login'
     return api.post(url,data)
 }

/**
 * 检查手机
 * @param phone 手机号
 */
export const checkRegisterPhone = (phone: string) => {
     const url = TOB +'/account/checkRegisterPhone'
     return api.post(url,{data: phone})
}


/**
 * 发送验证码
 * { phone: '', verify: '' }
 * @param data 
 */
export const sendSms = (data: Login) => {
     const url = TOB + '/account/sendSms'
     return api.post(url,data)
}

/**
 *  @description 生成图形验证嘛
 */
export const getValidateCode = TOB + '/account/getValidateCode'

/**
 * 登录 手机号 + 短信验证码
 * { phone: '', verify: '' }
 * @param data { phone: '', verify: '' }
 */
export const accountSmsLogin = (data: Login) => {
     const url = TOB + '/account/smsLogin'
     return api.post(url,data)
}


const configs: any = {}
const _sign = (jsapi_ticket: any) => {
    const noncestr = randomString(17)
 const timestamp = new Date().getTime()
 const url = window.location.href
 const str = `jsapi_ticket=${jsapi_ticket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${url}`
 const signature = sha1(str)
 configs['timestamp'] = timestamp
 configs['noncestr'] = noncestr
 configs['signature'] = signature
 wx.config({
   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
   appId: 'wx5f2d974aa7988c50', // 必填，公众号的唯一标识
   timestamp: timestamp, // 必填，生成签名的时间戳
   nonceStr: noncestr, // 必填，生成签名的随机串
   signature: signature,// 必填，签名
   jsApiList: ['wx-open-launch-weapp'], // 必填，需要使用的JS接口列表
   openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
 })
 wx.ready(() => { 
     console.log('')
 })
 wx.error((e: any) => {
    //initSignature()
     const { errMsg } = e
     /* if (errMsg == 'config:invalid signature') {
         let timers: any = setTimeout(() => {
            clearTimeout(timers)
            timers = null 
            initSignature ()
         }, 1200)
        // localStorage.removeItem(SET_SIGNATURE)
     } */
     console.log(e, '错误了...')
     //
 })
}

/**
 * 微信签名
 */
export async function initSignature () {
    const local: any = localStorage.getItem(SET_SIGNATURE)
    if (!local) {
        const res: any = await initJS()
        localStorage.setItem(SET_SIGNATURE, res.data)
        _sign(res.data)
    } else {
        _sign(local)
    }
}