import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { Storage, wxCode } from '@/utils/index'
import { SET_USER } from '@/utils/constant'
import {  remaining } from '@/api/index'

import Vconsole from 'vconsole'

import 'lib-flexible/flexible.js'
import '@/assets/css/index.less'

import { 
    RadioGroup, 
    Radio, 
    Tab, 
    Tabs, 
    Icon, 
    Dialog, 
    Field, 
    Switch, 
    Toast,
    DropdownMenu, 
    DropdownItem,
    Form,
    Button,
    List,
    Popup,
    Cascader,
    Checkbox, 
    CheckboxGroup,
    Uploader,
    Overlay,
    Cell
 } from 'vant'
import 'vant/lib/index.css'


const arry = [
    RadioGroup, 
    Radio, 
    Tab, 
    Tabs, 
    Icon, 
    Dialog, 
    Field, 
    Switch, 
    Toast,
    DropdownMenu, 
    DropdownItem,
    Form,
    Button,
    List,
    Popup,
    Cascader,
    Checkbox, 
    CheckboxGroup,
    Uploader,
    Overlay,
    Cell,
]

const vue: any = createApp(App)
/* const vConsole = new Vconsole()
vue.use(vConsole) */

arry.forEach(item => vue.use(item))

Storage.isExpires(SET_USER)

const _remaining = async () => {
    const res:any  = await remaining()
    store.commit('setAccount', res.data)
    localStorage.setItem('M', res.data)
}


const bool = localStorage.getItem(SET_USER)
if (bool) {
    _remaining()
}

vue.config.isCustomElement = (tag: any) => tag.startsWith('wx-open-');
vue.config.globalProperties.$toast = Toast;

vue.use(store).use(router).mount('#app')
